import { Box, Header } from '@amzn/awsui-components-react';
import React from 'react';
import '../with_page/css/index.css';
import DocLink from '../doc_link/utils/DocLink';
import getDocById from '../doc_link/utils/getDocById';

export default () => {
  return (
    <Header
      className="header"
      actions={
        <Box float="right" margin={{ right: 'xxl' }}>
          <DocLink pdf={getDocById(process.env.REACT_APP_FAQ_FILE_ID)} text="Need help?" />
        </Box>
      }
    />
  );
};
