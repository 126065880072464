import FAQDocUK from '../pdfs/RadicalFAQ_UK.pdf';
import FAQDocUS from '../pdfs/RadicalFAQ_US.pdf';
import { FAQ_UK, FAQ_US } from '../../../constants/documentIds';

const getDocById = docId => {
  switch (docId) {
    case FAQ_UK:
      return FAQDocUK;
    case FAQ_US:
      return FAQDocUS;
    default:
      return null;
  }
};
export default getDocById;
