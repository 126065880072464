import { get, isEmpty } from 'lodash';
import { getEntityListPath } from '../../../../common/constants/surveyType';

const validateActivityDualAllocation = (pageElements, surveyType, activityDualAllocation) => {
  const entityData = get(pageElements, getEntityListPath(surveyType), []);
  const activityData = activityDualAllocation.data;

  if (isEmpty(activityData) || Object.keys(activityData).length < entityData.length) {
    return false;
  }

  let validation = true;

  entityData.forEach(entity => {
    Object.keys(activityData[entity.recordId]).forEach(project => {
      if (Number(activityData[entity.recordId][project].total) !== 100) {
        validation = false;
      }
    });
  });

  return validation;
};

export default validateActivityDualAllocation;
