import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import PropTypes from 'prop-types';
import DocLink from '../../../../../common/components/doc_link/utils/DocLink';
import getDocById from '../../../../../common/components/doc_link/utils/getDocById';

const Introduction = ({ fgbs }) => (
  <React.Fragment>
    <SpaceBetween size="l" direction="horizontal">
      <Box fontSize="heading-xl" fontWeight="normal" variant="h2">
        Your survey(s) - primary assignee
      </Box>
      <Box padding={{ top: 's' }}>
        <DocLink
          pdf={getDocById(process.env.REACT_APP_FAQ_FILE_ID)}
          text="Why am I the primary assignee?"
          variant="info"
        />
      </Box>
    </SpaceBetween>
    <Box variant="p" color="text-body-secondary">
      You are the primary assignee of the following survey(s). Your progress will be reported to{' '}
      {fgbs}. Make sure you complete the survey(s) by the given due date.
    </Box>
  </React.Fragment>
);

Introduction.propTypes = {
  fgbs: PropTypes.string.isRequired,
};

export default Introduction;
