import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  ExpandableSection,
  Modal,
  SpaceBetween,
  ColumnLayout,
  Header,
} from '@amzn/awsui-components-react';
import { filter, forEach, get, isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import { round, sum } from 'lodash/math';
import { values } from 'lodash/object';
import * as XLSX from 'xlsx';
import { connect, useDispatch } from 'react-redux';
import { getEntityData, getSurveyEntity } from '../../../../../common/constants/surveyType';
import { validationInitialState } from '../../../constants/steps-config';
import ActivityAllocationGrid from './ActivityAllocationGrid/ActivityAllocationGrid';
import { isReadOnly } from '../../../utils/survey_page_utils';
import FillMatrixWithExcel from '../../AllocationGrid/FillMatrixWithExcel';
import { setActivityDualAllocation as setActivityDualAllocationAction } from '../../../redux/actions/activityDualAllocationActions';
import { setCopyGridValues as setCopyGridValuesAction } from '../../../redux/actions/copyGridValuesActions';
import './ActivityDualAllocation.css';

const ActivityDualAllocation = ({
  state,
  setState,
  pageContents,
  validationState,
  setValidationState,
  onNavigate,
  surveyType,
  surveyDetails,
  activityDualAllocation,
  projectAllocation,
  copyGridValues,
}) => {
  const dispatch = useDispatch();
  const entityData = useMemo(() => getEntityData(pageContents, surveyType), [
    pageContents,
    surveyType,
  ]);
  const DefaultActivities = get(state, 'Activity.data.defaultItems', []);
  const selectedActivities = get(state, 'Activity.data.selectedActivities', []);
  const activityRemoved = get(validationState, 'activityAllocationState.activityRemoved', false);

  const setCopyGridValues = (value, column) => {
    dispatch(
      setCopyGridValuesAction({
        ...copyGridValues,
        activityCopiedValues: value,
        activityCopiedColumn: column,
      }),
    );
  };

  // New function to extract list of projects for each employee with non-zero allocation
  const projectListForEmployees = useMemo(() => {
    const allocations = get(projectAllocation, 'data', {});
    const projects = get(state, 'Project.data.selectedProjects', []);

    const projectMap = projects.reduce(
      (accumulator, project) => ({
        ...accumulator,
        [project.id]: project.name,
      }),
      {},
    );

    const ProjectsForEmployees = {};

    forEach(allocations, (projectAllocations, employeeId) => {
      ProjectsForEmployees[employeeId] = Object.keys(projectAllocations)
        .filter(projectId => parseFloat(projectAllocations[projectId]) > 0 && projectId !== 'total')
        .map(projectId => ({
          id: projectId,
          name: projectMap[projectId],
          allocation: projectAllocations[projectId],
        }));
    });

    return ProjectsForEmployees;
  }, [projectAllocation, state]);

  const validateActivityAllocation = activityData => {
    if (isEmpty(activityData) || Object.keys(activityData).length !== entityData.length) {
      return false;
    }

    return Object.keys(activityData).every(entity => {
      const projects = activityData[entity];
      const selectedProjects = projectListForEmployees[entity] || [];
      const selectedProjectCount = selectedProjects.length;

      if (Object.keys(projects).length !== selectedProjectCount) {
        return false;
      }

      return Object.keys(projects).every(project => {
        return Number(projects[project].total) === 100;
      });
    });
  };

  const setActivityDualAllocation = val => {
    const newData = {
      ...val,
      data: {},
    };
    entityData.forEach(entity => {
      newData.data[entity.id] = val.data[entity.id];
    });
    dispatch(
      setActivityDualAllocationAction({
        ...newData,
        error: !validateActivityAllocation(newData?.data),
        errorMessage: null,
      }),
    );
  };

  // Ensure ActivityDualAllocation is initialized in the state if undefined
  // Added to make this backward compatible with single allocation survey
  useEffect(() => {
    if (!activityDualAllocation) {
      setActivityDualAllocation({
        data: {},
        error: !validateActivityAllocation({}),
        errorMessage: null,
      });
    }
  }, []);

  // Checks if ActivityDualAllocation is in sync with selected activities and projects for each employee
  useEffect(() => {
    const oldState = activityDualAllocation.data || {};
    const Activities = map(
      !selectedActivities ? DefaultActivities : [...DefaultActivities, ...selectedActivities],
      ob => ob.id,
    );
    const newState = {};

    forEach(entityData, entity => {
      const entityDataStore = {};

      forEach(projectListForEmployees[entity.id], project => {
        const projectId = project.id;
        const projectData = {};

        forEach(Activities, activityId => {
          if (
            oldState[entity.id]?.[projectId]?.[activityId] &&
            parseFloat(oldState[entity.id][projectId][activityId]) > 0
          ) {
            projectData[activityId] = oldState[entity.id][projectId][activityId];
          }
        });

        projectData.total = sum(map(values(projectData), val => round(parseFloat(val), 1)))
          .toFixed(1)
          .toString();

        entityDataStore[projectId] = projectData;
      });

      newState[entity.id] = entityDataStore;
    });

    forEach(newState, (projects, employeeId) => {
      const validProjectIds = projectListForEmployees[employeeId]?.map(project => project.id) || [];

      newState[employeeId] = Object.keys(projects).reduce((acc, projectId) => {
        if (validProjectIds.includes(projectId)) {
          return { ...acc, [projectId]: projects[projectId] };
        }
        return acc;
      }, {});
    });

    if (JSON.stringify(newState) !== JSON.stringify(activityDualAllocation.data)) {
      setActivityDualAllocation({
        data: newState,
        error: !validateActivityAllocation(newState),
        errorMessage: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivities, state.Project.data.selectedProjects]);

  const activities = !selectedActivities
    ? DefaultActivities
    : [...DefaultActivities, ...selectedActivities];

  const closeNoAllocationModal = () => {
    setValidationState(validationInitialState);
  };

  useEffect(() => {
    if (activityRemoved) {
      setValidationState(validationInitialState);
      const detail = {
        requestedStepIndex: state.activeStep + 1,
      };
      onNavigate({ detail });
    }
  }, [activityRemoved]);

  const removeActivityFromSelectedList = () => {
    const defaultActivities = get(state, 'Activity.data.defaultItems', []);
    const selectedActivity = get(state, 'Activity.data.selectedActivities', []);
    const removeActivityId = get(validationState, 'activityAllocationState.activityId', '');
    const currentDefaultActivity = filter(
      defaultActivities,
      activity => activity.id !== removeActivityId,
    );
    const currentSelectedActivity = filter(
      selectedActivity,
      activity => activity.id !== removeActivityId,
    );
    setState({
      key: 'Activity',
      value: {
        ...get(state, 'Activity', {}),
        data: {
          ...get(state, 'Activity.data', {}),
          defaultItems: currentDefaultActivity,
          selectedActivities: currentSelectedActivity,
        },
      },
    });
    setValidationState(prevState => ({
      ...prevState,
      activityAllocationState: {
        ...prevState.activityAllocationState,
        activityRemoved: true,
      },
    }));
  };

  const { error, errorMessage } = activityDualAllocation;

  /**
   * this method exports excel for single project surveys, like business component survey.
   */
  const exportActivityAllocationToExcel = (activityData, projectsSelected, activityList) => {
    const workbook = XLSX.utils.book_new();
    const projectId = projectsSelected[0].id;

    const worksheetData = [];
    const headerRow = ['Activities', ...(activityList || []).map(activity => activity.name)];
    worksheetData.push(headerRow);

    (entityData || []).forEach(employee => {
      const rowData = [`${employee.name} | ${employee.employeeAlias} | ${employee.id}`];
      activityList.forEach(activity => {
        const cellValue = activityData?.[employee.id]?.[projectId]?.[activity.id] || '';
        rowData.push(cellValue);
      });
      worksheetData.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ActivityAllocation');

    XLSX.writeFile(workbook, 'activity-allocation.xlsx');
  };

  const exportActivityDualAllocationToExcel = (
    activityData,
    projectsForEmployees,
    activityList,
  ) => {
    const workbook = XLSX.utils.book_new();

    entityData.forEach(employee => {
      const employeeId = employee.id;
      const worksheetData = [];
      const headerRow = [
        'Activities',
        ...(projectsForEmployees[employeeId] || []).map(project => project.name),
      ];
      worksheetData.push(headerRow);

      activityList.forEach(activity => {
        const rowData = [activity.name];
        (projectsForEmployees[employeeId] || []).forEach(project => {
          const cellValue = activityData?.[employeeId]?.[project.id]?.[activity.id] || '';
          rowData.push(cellValue);
        });
        worksheetData.push(rowData);
      });

      if (worksheetData.length > 1) {
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const employeeName = employee.name;
        XLSX.utils.book_append_sheet(workbook, worksheet, employeeName);
      }
    });

    XLSX.writeFile(workbook, 'activity-dual-allocation.xlsx');
  };

  const handleExportClick = () => {
    const activityData = activityDualAllocation.data;
    const activityList = activities;
    const projectsSelected = get(state, 'Project.data.selectedProjects', []);

    if (projectsSelected.length < 2) {
      exportActivityAllocationToExcel(activityData, projectsSelected, activityList);
    } else {
      exportActivityDualAllocationToExcel(activityData, projectListForEmployees, activityList);
    }
  };

  const getEmployeeIdByName = employeeName => {
    const employee = entityData.find(entity => entity.name === employeeName);
    return employee ? employee.id : null;
  };

  const getProjectIdByName = (projectName, employeeProjects) => {
    const project = employeeProjects.find(projectItem => projectItem.name === projectName);
    return project ? project.id : null;
  };

  const getActivityIdByName = activityName => {
    // eslint-disable-next-line no-shadow
    const activityItem = activities.find(activityItem => activityItem.name === activityName);
    return activityItem ? activityItem.id : null;
  };

  /**
   * this method parses excel for single project surveys, like business component survey.
   */
  const parseExcelForActivityAllocation = (file, projectsSelected) => {
    const reader = new FileReader();
    const projectId = projectsSelected[0].id;

    reader.onload = function parseExcelOnLoad(e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const gridData = {};

      const worksheet = workbook.Sheets.ActivityAllocation;
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const [headerRow, ...dataRows] = jsonData;

      dataRows.forEach(row => {
        const employeeIdentifierSplit = row[0].split(' | ');
        const employeeId = employeeIdentifierSplit[employeeIdentifierSplit.length - 1];
        gridData[employeeId] = {};
        gridData[employeeId][projectId] = {};

        headerRow.slice(1).forEach((activityName, index) => {
          const activityId = getActivityIdByName(activityName);
          if (activityId) {
            const cellValue = row[index + 1] !== undefined ? String(row[index + 1]) : '0';

            if (cellValue !== '' && parseFloat(cellValue) !== 0) {
              gridData[employeeId][projectId][activityId] = cellValue;
            }
          }
        });

        Object.keys(gridData[employeeId]).forEach(projectIdentifier => {
          gridData[employeeId][projectIdentifier].total = Object.values(
            gridData[employeeId][projectIdentifier],
          )
            .reduce((totalSum, val) => totalSum + parseFloat(String(val) || '0'), 0)
            .toFixed(1);
        });
      });

      setActivityDualAllocation({
        ...activityDualAllocation,
        data: gridData,
        error: !validateActivityAllocation(gridData),
        errorMessage: null,
      });
    };

    reader.readAsBinaryString(file);
  };

  const parseExcelForActivityDualAllocation = file => {
    const reader = new FileReader();

    reader.onload = function parseExcelOnLoad(e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const gridData = {};

      workbook.SheetNames.forEach(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const [headerRow, ...dataRows] = jsonData;

        const employeeId = getEmployeeIdByName(sheetName);

        if (employeeId) {
          gridData[employeeId] = {};
          const employeeProjects = projectListForEmployees[employeeId];

          dataRows.forEach(row => {
            const activityName = row[0];
            const activityId = getActivityIdByName(activityName);

            if (activityId) {
              headerRow.slice(1).forEach((projectName, index) => {
                const projectId = getProjectIdByName(projectName, employeeProjects);

                if (projectId) {
                  const cellValue = row[index + 1] !== undefined ? String(row[index + 1]) : '0';

                  if (cellValue !== '' && parseFloat(cellValue) !== 0) {
                    if (!gridData[employeeId][projectId]) {
                      gridData[employeeId][projectId] = {};
                    }

                    gridData[employeeId][projectId][activityId] = cellValue;
                  }
                }
              });
            }
          });

          Object.keys(gridData[employeeId]).forEach(projectId => {
            gridData[employeeId][projectId].total = Object.values(gridData[employeeId][projectId])
              .reduce((totalSum, val) => totalSum + parseFloat(String(val) || '0'), 0)
              .toFixed(1);
          });
        }
      });

      setActivityDualAllocation({
        ...activityDualAllocation,
        data: gridData,
        error: !validateActivityAllocation(gridData),
        errorMessage: null,
      });
    };

    reader.readAsBinaryString(file);
  };

  const handleFileSelected = file => {
    if (file) {
      const projectsSelected = get(state, 'Project.data.selectedProjects', []);
      if (projectsSelected.length < 2) {
        parseExcelForActivityAllocation(file, projectsSelected);
      } else {
        parseExcelForActivityDualAllocation(file);
      }
    }
  };

  const [excelUploadModal, setExcelUploadModal] = useState(false);

  return (
    <SpaceBetween size="l">
      {process.env.REACT_APP_COUNTRY === 'gbr' && (
        <div className="activity-definitions-box">
          <ExpandableSection headerText="Activity definition" variant="container">
            <ColumnLayout columns={2}>
              <div>
                <Box variant="awsui-key-label">Qualifying activities</Box>
                <ul>
                  <li>Activities needed to resolve the scientific or technological uncertainty.</li>
                  <li>Design, coding, testing and analysis.</li>
                  <li>
                    Designing, constructing and testing prototypes / proof of concepts (POCs).
                  </li>
                </ul>
                <Box variant="awsui-key-label">Non-qualifying activities</Box>
                <ul>
                  <li>
                    Routine / business as usual activities that do not contribute to resolving the
                    technological or scientific uncertainties.
                  </li>
                </ul>
              </div>
              <div>
                <Box variant="awsui-key-label">Supervision & support</Box>
                <ul>
                  <li>
                    Project planning, managing and directing the R&D activities - defining the
                    technological objectives, assessing technological feasibility, estimating
                    development time. Includes including resource planning, project reviews, project
                    budgeting.
                  </li>
                </ul>
                <Box variant="awsui-key-label">General administrative</Box>
                <ul>
                  <li>
                    Time spent on activities such as team meetings, appraisals, budgeting, training,
                    time reporting.
                  </li>
                </ul>
                <Box variant="awsui-key-label">KTLO / On-Call / other commercial activities</Box>
                <ul>
                  <li>
                    Time spent on non-project market research, non-technical business development.
                  </li>
                </ul>
              </div>
            </ColumnLayout>
          </ExpandableSection>
        </div>
      )}

      {error && (
        <Alert visible="true" dismissAriaLabel="Close alert" type="error">
          You must allocate a total of 100% to each project of each {getSurveyEntity(surveyType)}
        </Alert>
      )}

      {errorMessage && (
        <Alert visible="true" dismissAriaLabel="Close alert" type="error">
          {errorMessage}
        </Alert>
      )}

      <Header
        headingTagOverride="h2"
        actions={
          <Box float="right">
            <SpaceBetween size="xxl" direction="horizontal">
              <Button
                onClick={() => setExcelUploadModal(true)}
                disabled={isReadOnly(surveyDetails)}
              >
                Fill matrix with Excel
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        Employee activity matrix
      </Header>

      <FillMatrixWithExcel
        isVisible={excelUploadModal}
        setModalVisible={setExcelUploadModal}
        onDownloadTemplate={() => handleExportClick()}
        onFillMatrix={handleFileSelected}
      />

      <ActivityAllocationGrid
        entityData={entityData}
        projectList={projectListForEmployees}
        activities={activities}
        surveyType={surveyType}
        surveyDetails={surveyDetails}
        activityDualAllocation={activityDualAllocation}
        setActivityDualAllocation={setActivityDualAllocation}
        copiedValues={copyGridValues.activityCopiedValues}
        copiedColumn={copyGridValues.activityCopiedColumn}
        setCopiedValues={setCopyGridValues}
      />

      <Modal
        onDismiss={closeNoAllocationModal}
        visible={get(validationState, 'activityAllocationState.modalVisible', false)}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="normal" onClick={closeNoAllocationModal}>
                Edit allocation
              </Button>
              <Button variant="primary" onClick={removeActivityFromSelectedList}>
                Proceed
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Activity has no time allocation"
      >
        <div>
          You didn&apos;t allocate any of the employee&apos;s time to activity{' '}
          <b>{get(validationState, 'activityAllocationState.activityName', '')}</b>. If you choose
          to proceed, this activity will be removed from your activity list.
        </div>
      </Modal>
    </SpaceBetween>
  );
};

ActivityDualAllocation.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  pageContents: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
  validationState: PropTypes.object.isRequired,
  setValidationState: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  activityDualAllocation: PropTypes.object.isRequired,
  projectAllocation: PropTypes.object.isRequired,
  copyGridValues: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activityDualAllocation: get(state, 'activityDualAllocation', {}),
  projectAllocation: get(state, 'projectAllocation', {}),
  copyGridValues: get(state, 'copyGridValues', {}),
});

export default connect(mapStateToProps)(ActivityDualAllocation);
