import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, SpaceBetween, Input, Box, Pagination } from '@amzn/awsui-components-react';
import { round } from 'lodash/math';
import './SectionedAllocationGrid.css';
import { isReadOnly } from '../../utils/survey_page_utils';

const SectionedAllocationGrid = ({
  columns,
  entityData,
  gridValues,
  setGridValues,
  surveyDetails,
}) => {
  const [expandedItems, setExpandedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  // const NO_DATA_FOUND_MESSAGE = 'No employee found matching the given criteria.';

  const [employeeQuery, setEmployeeQuery] = React.useState('');
  const [numOfMatches, setNumOfMatches] = React.useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const PAGE_SIZE = 10;

  const entityFilter = useMemo(() => {
    return employeeQuery.trim().toLowerCase();
  }, [employeeQuery]);

  useEffect(() => {
    setCurrentPageIndex(1);
  }, [employeeQuery]);

  const employeeDataToBeRendered = useMemo(() => {
    let resultData = entityData;
    if (entityFilter !== '') {
      resultData = entityData.filter(employee => {
        return Object.values(employee)
          .join(' ')
          .toLowerCase()
          .includes(entityFilter);
      });
    }

    setNumOfMatches(resultData.length);
    return resultData;
  }, [entityData, entityFilter]);

  const dataOnPage = useMemo(() => {
    const res = employeeDataToBeRendered.slice(
      Math.imul(currentPageIndex - 1, PAGE_SIZE),
      Math.imul(currentPageIndex, PAGE_SIZE),
    );
    setLoading(false);
    return res;
  }, [employeeDataToBeRendered, currentPageIndex]);

  const TOTAL_PAGE_COUNT = useMemo(() => {
    return Math.ceil(employeeDataToBeRendered.length / PAGE_SIZE);
  }, [employeeDataToBeRendered.length]);

  const getTotalForArray = useCallback(values => {
    if (!values) return '0';
    return Object.entries(values)
      .reduce((sum, [key, value]) => (key !== 'total' ? sum + Number(value) : sum), 0)
      .toString();
  }, []);

  const createUpdatedGridObject = useCallback(
    (colEntity, rowEntity, value) => {
      const newRowValues = {
        ...gridValues[rowEntity.id],
        [colEntity.id]: round(Number(value), 1).toString(),
      };
      return {
        ...gridValues,
        [rowEntity.id]: {
          ...newRowValues,
          total: getTotalForArray(newRowValues),
        },
      };
    },
    [gridValues, getTotalForArray],
  );

  const onBlurHandler = useCallback(
    (colEntity, rowEntity) => {
      const value = gridValues[rowEntity.id]?.[colEntity.id] || 0;
      setGridValues(createUpdatedGridObject(colEntity, rowEntity, value));
    },
    [gridValues, createUpdatedGridObject, setGridValues],
  );

  const onInputChangeHandler = useCallback(
    (cellValue, colEntity, rowEntity, inputBoxId) => {
      const numValue = Number(cellValue);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(numValue) || numValue < 0) return;

      const inputElement = document.getElementById(inputBoxId);
      if (inputElement) {
        inputElement.style.borderColor = numValue > 100 ? 'red' : '#aab7b8';
      }

      setGridValues(createUpdatedGridObject(colEntity, rowEntity, cellValue || '0'));
    },
    [createUpdatedGridObject, setGridValues],
  );

  const renderCell = useCallback(
    (rowEntity, column) => {
      const cellValue = gridValues[rowEntity.id]?.[column.id] || '';

      if (column.id === 'employees') {
        return (
          <div>
            <b>{rowEntity.name}</b>
            <div className="employee-description">
              <SpaceBetween size="l" direction="horizontal">
                {rowEntity.jobTitle && <span>Job title: {rowEntity.jobTitle}</span>}
                {rowEntity.supervisorName && (
                  <span>
                    {`Reports to: ${rowEntity.supervisorName} ${
                      rowEntity.supervisorAlias ? `(${rowEntity.supervisorAlias})` : ''
                    }`}
                  </span>
                )}
                {rowEntity.smeName && (
                  <span>
                    {`SME Name: ${rowEntity.smeName} ${
                      rowEntity.smeAlias ? `(${rowEntity.smeAlias})` : ''
                    }`}
                  </span>
                )}
              </SpaceBetween>
            </div>
          </div>
        );
      }
      if (rowEntity.children) {
        return <div />;
      }

      const inputBoxId = `${rowEntity.id}${column.id}`;

      return (
        <div className="percent-input-box-area">
          <SpaceBetween direction="horizontal" size="xs" className="render-cell">
            <div className="percent-input-box">
              <Input
                key={inputBoxId}
                type="text"
                value={cellValue}
                onChange={({ detail }) =>
                  onInputChangeHandler(detail.value, column, rowEntity, inputBoxId)
                }
                disabled={isReadOnly(surveyDetails)}
                onBlur={() => onBlurHandler(column, rowEntity)}
              />
            </div>
            <div className="percent-sign">%</div>
          </SpaceBetween>
        </div>
      );
    },
    [gridValues, onInputChangeHandler, onBlurHandler],
  );

  const columnsToRender = useMemo(
    () =>
      columns.map(c => ({
        id: c.id,
        header: c.label,
        cell: rowEntity => renderCell(rowEntity, c),
        sticky: 'left',
        stripe: rowEntity => Boolean(rowEntity.children),
        size: 'large',
        minWidth: 174,
        maxWidth: 174,
      })),
    [columns, renderCell],
  );

  // const sectionedGridData = useMemo(() => {
  //   const newData = {};
  //
  //   dataOnPage.forEach(entity => {
  //     const sectionHeader = getSectionHeader(entity);
  //     if (!newData[sectionHeader]) {
  //       newData[sectionHeader] = {
  //         id: sectionHeader,
  //         name: sectionHeader,
  //         children: [],
  //         size: 'small',
  //       };
  //     }
  //     const entityItem = {
  //       id: entity.id,
  //       name: entity.name,
  //       size: 'small',
  //       ...columnsToRender.reduce(
  //         (acc, column) => ({
  //           ...acc,
  //           [column.id]: gridValues[entity.id]?.[column.id] ?? '',
  //         }),
  //         {},
  //       ),
  //     };
  //     newData[sectionHeader].children.push(entityItem);
  //   });
  //   return Object.values(newData);
  // }, [dataOnPage, getSectionHeader, columnsToRender, gridValues]);

  return (
    <>
      <SpaceBetween size="l">
        <Box className="table-heading-sectioned-alloc-grid">
          <SpaceBetween size="m" direction="horizontal" className="search-bar-sectioned-alloc-grid">
            <Input
              className="search-input"
              onChange={({ detail }) => setEmployeeQuery(detail.value)}
              value={employeeQuery}
              placeholder="Search"
              type="search"
            />
            {employeeQuery.length > 0 && <span>{numOfMatches} matches</span>}
          </SpaceBetween>
          <Box variant="h3" className="pagination-component-sectioned-alloc-grid">
            <Pagination
              currentPageIndex={currentPageIndex}
              onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex)}
              pagesCount={TOTAL_PAGE_COUNT}
            />
          </Box>
        </Box>
        <Table
          header={undefined}
          columnDefinitions={columnsToRender}
          items={dataOnPage}
          expandableRows={{
            getItemChildren: item => item.children,
            isItemExpandable: item => Boolean(item.children),
            expandedItems,
            onExpandableItemToggle: ({ detail }) => {
              setExpandedItems(prev => {
                const next = new Set(prev.map(item => item.name));
                if (detail.expanded) next.add(detail.item.name);
                else next.delete(detail.item.name);
                return [...next].map(name => ({ name }));
              });
            },
          }}
          expandedItems={expandedItems}
          loading={loading}
          variant="borderless"
          stripedRows
          stickyHeader
          stickyColumns={{ first: 1, last: 0 }}
          trackBy="name"
          wrapLines
        />
      </SpaceBetween>
    </>
  );
};

SectionedAllocationGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  // getSectionHeader: PropTypes.func.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  entityData: PropTypes.array.isRequired,
  gridValues: PropTypes.object.isRequired,
  setGridValues: PropTypes.func.isRequired,
};

export default SectionedAllocationGrid;
