import React from 'react';
import { Link } from '@amzn/awsui-components-react/polaris';
import { PROJECT_TEMPLATE_IDS } from '../../../common/constants/templateIds';
import { SURVEY_TYPE } from '../../../common/constants/surveyType';
import WizardDescription from '../components/WizardUtils/DescriptionUtil/WizardDescription';

const SIM_LINK_UK =
  'https://issues.amazon.com/issues/create?assignedFolder=458c4087-2eae-4452-9ea0-48748c0b66ed';
const SIM_LINK_US =
  'https://issues.amazon.com/issues/create?assignedFolder=d5511708-1409-45d8-993d-ee250b29dcc1';

const getSIMLink = () => {
  switch (process.env.REACT_APP_FAQ_FILE_ID) {
    case 'FAQ_US':
      return SIM_LINK_US;
    case 'FAQ_UK':
      return SIM_LINK_UK;
    default:
      return SIM_LINK_US;
  }
};

const SIM_LINK = getSIMLink();

const DEFAULT_TOOLS_CONTENT = {
  Employee: {
    default: {
      title: 'Employee',
      content: <div>Employee Info</div>,
    },
  },
  Vendor: {
    default: {
      title: 'Supplier',
      content: <div>Vendor Info</div>,
    },
  },
  Project: {
    default: {
      title: 'Projects',
      content: <p>Projects info</p>,
    },
  },
  Activity: {
    default: {
      title: 'Activity',
      content: <div>Activity Info</div>,
    },
  },
  ProjectAllocation: {
    default: {
      title: 'Project allocation',
      content: <div>Project Allocation Info</div>,
    },
  },
  ActivityDualAllocation: {
    default: {
      title: 'Activity allocation',
      content: <div>Activity Allocation Info</div>,
    },
  },
  Submit: {
    default: {
      title: 'Submit Attestation',
      content: <div>Submit Attestation</div>,
    },
  },
};

export const TOOLS_CONTENT_FN = templateId => {
  if (!PROJECT_TEMPLATE_IDS.includes(templateId)) {
    return {
      ...DEFAULT_TOOLS_CONTENT,
      ProjectDocuments: {
        default: {
          title: 'Projects documents',
          content: <p>Projects Documents info</p>,
        },
      },
    };
  }
  return DEFAULT_TOOLS_CONTENT;
};

export const descriptions = (stateKey, year, surveyType, derivedClientId, projectName) => {
  const descriptionKey = `${stateKey}_${surveyType}`;
  const country = derivedClientId === 'Radical' ? 'US' : 'UK';
  switch (descriptionKey) {
    case 'Employee_LaborDual':
      return (
        <WizardDescription
          stepSummary={
            <div>
              {/* eslint-disable-next-line max-len */}
              {`This survey collects information to support the US Research & Development Tax Credit for the calendar year ${year}. This is an important initiative that provides significant savings for Amazon.`}
            </div>
          }
          stepSummaryHeading="Purpose of this step"
          stepDescription={
            <div>
              {/* eslint-disable-next-line max-len */}
              {`The list below represents the US based employees who have contributed to the Business Component `}
              <strong>{`“${projectName}”`}</strong>
              {/* eslint-disable-next-line max-len */}
              {`. The proportion of time that these employees contributed to this Business Component is based on allocations to projects obtained in the initial R&D Study surveys. In this survey, you are requested to review the pre-populated allocations for each employee that are derived from the initial R&D Study surveys and make any changes to reflect accurately the work performed in this Business Component for the year ended December 31, ${year}.`}
            </div>
          }
        />
      );
    case 'Employee_Labor':
      return country === 'US' ? (
        <WizardDescription
          stepDescription={
            <div>
              {/* eslint-disable-next-line max-len */}
              {`Review the employee(s) assigned to your survey. You will be asked to enter the percentage of time each employee spent on various activities and development projects in ${year}.`}
              <br />
              <br />
              {/* eslint-disable-next-line max-len */}
              {`The list below represents ${country} employees who reported to you (direct or skip level) based on HR reports at December 31, ${year}, and/or includes yourself. The list may also show ${country} employees who were part of the wider team but were not part of your direct reporting chain (for example because their direct or skip manager is no longer at Amazon). The survey may show employees who are no longer at Amazon but worked in ${year} and were aligned with you as their direct or skip manager before they left.`}
            </div>
          }
          stepSummary={undefined}
          stepSummaryHeading={undefined}
        />
      ) : (
        <WizardDescription
          stepDescription={
            <div>
              {// eslint-disable-next-line max-len
              `The list below represents the ${country} based employees who have been assigned to you based on the HR information ${
                country === 'US' ? '' : '(as of September 30th) '
                // eslint-disable-next-line max-len
              }available to the Amazon Tax team. On the subsequent survey pages, you will be asked to assign each individual’s time across the projects and activities they worked on during the year period ended December 31, ${year}.`}
              <br />
              <br />
              The list below should represent all technical individuals who:
              <br />
              <ul>
                <li>
                  reported to you (direct or skip level) for all of or the majority of the time they
                  were employed by Amazon in the {country} during the year ended December 31, {year}
                  ;
                </li>
                <li>
                  and/or includes yourself, either as a self-survey (no other employees listed) or
                  in addition to employees that reported to you (direct or skip level);
                </li>
                <li>and worked at Amazon for more than 3 months of the year.</li>
              </ul>
              {
                // eslint-disable-next-line max-len
                'Based on the above, if you believe anyone below has been incorrectly assigned to you, or you would like to add/remove any employees, please '
              }
              <Link href={SIM_LINK} external>
                submit a SIM ticket.
              </Link>
              <br />
              Click ‘Next’ to go to the next step.
            </div>
          }
          stepSummary={
            // eslint-disable-next-line max-len
            country === 'US'
              ? undefined
              : // eslint-disable-next-line max-len
                `This survey collects information for the ${country} Research & Development Expenditure Credit claim for the calendar year ${year}. This is an important initiative that provides significant savings to the company and your departments budget.`
          }
          stepSummaryHeading="Purpose of this step"
        />
      );

    case 'Project_Labor':
      return country === 'US' ? (
        <WizardDescription
          stepSummary={undefined}
          stepSummaryHeading={undefined}
          stepDescription={
            <div>
              Select all projects you and/or your employees worked on in {year}. A search function
              is available to help you find relevant projects already present in the list.
              <br />
              <br />
              If you do not see relevant project(s), you can add these using the &quot;Add
              project&quot; button. Added project(s) will be visible to all survey takers in the
              same department / cost center. Any selected projects will then appear on the next page
              of the survey.
              <br />
              <br />
              {'If you have any questions, please '}
              <Link href={SIM_LINK} external>
                submit a SIM ticket
              </Link>
            </div>
          }
        />
      ) : (
        <WizardDescription
          stepDescription={
            <div>
              {
                // eslint-disable-next-line max-len
                'Select the projects that your team worked on either directly contributing, supervising, or supporting.'
              }
              <br />
              <br />
              {
                // eslint-disable-next-line max-len
                'If a project your team worked on is not listed below, please add the project by clicking ‘Create project’ button.'
              }
              <br />
              <br />
              {
                // eslint-disable-next-line max-len
                'Capture the balance of your team’s time under ‘Non-Project’ (i.e. on-call, administrative time, inactive and pending off-boarding).'
              }
              <br />
              <br />
              {'If you have any questions, please '}
              <Link href={SIM_LINK} external>
                submit a SIM ticket
              </Link>
            </div>
          }
          stepSummary={
            // eslint-disable-next-line max-len
            country === 'US'
              ? undefined
              : // eslint-disable-next-line max-len
                `This step identifies the projects your team worked on during ${year}.`
          }
          stepSummaryHeading="Purpose of this step"
        />
      );

    case 'ProjectsDocument_Labor':
      return (
        'It is recommended that you provide documentation to support employee involvement' +
        ' in the projects ' +
        'reported in the survey. Retaining documentation to support the support the rep' +
        'orted is extremely importa' +
        'nt. Generally, it is necessary to retain documentation for at least seven years.' +
        ' Please keep copies of al' +
        'l the documents included on this list in secure storage. Based on your responses,' +
        ' the Tax Department ' +
        'may contact you in the future to obtain additional documentation.'
      );

    case 'Activity_Labor':
      return (
        <WizardDescription
          stepDescription={
            country === 'US' ? (
              <div>
                {// eslint-disable-next-line max-len
                `Select all the activities performed by you and/or the employees on your survey in ${year}. Selected activities will then appear on the next page of the survey.`}
                <br />
                <br />
                {'If you have any questions, please '}
                <Link href={SIM_LINK} external>
                  submit a SIM ticket
                </Link>
              </div>
            ) : (
              <div>
                {// eslint-disable-next-line max-len
                `Select all the activities your team performed during ${year}. The following activities will automatically be selected and appear on the next page:`}
                <br />
                <ul>
                  <li>General administrative</li>
                  <li>KTLO / On-Call / other commercial activities</li>
                </ul>
                <strong>Note:</strong>
                {/* eslint-disable-next-line max-len */}
                {`PTO, such as vacation, sick days, statutory holidays, etc, are not listed here. The objective of the survey is to allocate available time worked during ${year}. Amazon Tax accounts for non-working time separately.`}
                <br />
                <br />
                {'If you have any questions, please '}
                <Link href={SIM_LINK} external>
                  submit a SIM ticket
                </Link>
              </div>
            )
          }
          stepSummary={
            // eslint-disable-next-line max-len
            country === 'US'
              ? undefined
              : // eslint-disable-next-line max-len
                `This question identifies the activities that were performed by the employees on your team during ${year}`
          }
          stepSummaryHeading="Purpose of this step"
        />
      );

    case 'AdjustProjectAllocation_LaborDual':
      return (
        <WizardDescription
          stepDescription={
            <div>
              {/* eslint-disable-next-line max-len */}
              {`This is a follow up to the previous R&D tax credit surveys. In this survey, the R&D tax team would like to learn more about how the employee(s) shown who are mapped to this Business Component spent their time in ${year}.`}
              <br />
              <br />
              {/* eslint-disable-next-line max-len */}
              The table below shows the total percentage of time that each employee worked on this
              Business Component. This percentage is derived from the allocations of each employee
              to projects that was collected in the earlier R&D Study survey. Please review the
              total employee time allocated to this Business Component and if needed, you can make
              changes to these percentage allocations.
              <br />
              <br />
              {'If you have any questions, please contact '}
              <Link href={`mailto:${process.env.REACT_APP_CONTACT_US_MAIL}`}>
                {process.env.REACT_APP_CONTACT_US_MAIL}
              </Link>
            </div>
          }
          stepSummary={
            // eslint-disable-next-line max-len
            `This step confirms the percentage of time worked by each employee on the Business Component during ${year}. This information helps Amazon Tax qualify the labor expenditures associated with each Business Component.`
          }
          stepSummaryHeading="Purpose of this step"
        />
      );

    case 'ProjectAllocation_Labor':
      return country === 'US' ? (
        <WizardDescription
          stepSummary={undefined}
          stepSummaryHeading={undefined}
          stepDescription={
            <div>
              Enter the percentage of time that each employee spent working on the respective
              projects. You must allocate 100% of each employee’s total working time in {year} for
              the survey to be considered complete.
              <br />
              <br />
              Alternatively, you can download the matrix to an excel file, fill it out offline and
              upload it back to this page.{' '}
              <b>Do not change the structure or format of downloaded file.</b>
              <br />
              <br />
              {'If you have any questions, please '}
              <Link href={SIM_LINK} external>
                submit a SIM ticket
              </Link>
            </div>
          }
        />
      ) : (
        <WizardDescription
          stepDescription={
            <>
              {// eslint-disable-next-line max-len
              `Below is the list of employees and the projects selected per the previous pages. For each employee across the top, please allocate 100% of their available working time during ${year} to the projects selected, or to ‘Non-Project’. Amazon Tax separately accounts for all forms of PTO and hire and termination dates; however, please account for any time spent by an inactive employee pending off-boarding to ‘Non-Project’.`}
              <br />
              <br />
              {'If you have any questions, please '}
              <Link href={SIM_LINK} external>
                submit a SIM ticket
              </Link>
            </>
          }
          stepSummary={
            // eslint-disable-next-line max-len
            country === 'US'
              ? undefined
              : // eslint-disable-next-line max-len
                `This step determines the percentage of time spent by each team member on projects during ${year}. This information helps Amazon Tax qualify the labour expenditures for each project. We suggest reviewing project timelines, SIMs, sprint plans/summaries, etc. to complete the allocations.`
          }
          stepSummaryHeading="Purpose of this step"
        />
      );

    case 'ActivityAllocation_Labor':
      return country === 'US' ? (
        <WizardDescription
          stepSummary={undefined}
          stepSummaryHeading={undefined}
          stepDescription={
            <div>
              {// eslint-disable-next-line max-len
              'Enter the percentage of time that each employee spent on relevant activities. You must allocate 100% of' +
                ` each employee’s time in ${year} for the survey to be considered complete.`}
              <br />
              <br />
              Alternatively, you can download the matrix to an excel file, fill it out offline and
              then upload it back to this page.{' '}
              <b> Do not change the structure or format of downloaded file.</b>
              <br />
              <br />
              {'If you have any questions, please '}
              <Link href={SIM_LINK} external>
                submit a SIM ticket
              </Link>
            </div>
          }
        />
      ) : (
        <WizardDescription
          stepSummary={undefined}
          stepSummaryHeading={undefined}
          stepDescription={
            <div>
              Enter the percentage of time each employee spent on the activity. You must allocate
              100% of each employee&apos;s time for the survey to be considered complete.
              Alternatively, download the matrix, fill it out offline and upload back.
              <strong> Do not change the format of downloaded file.</strong>
            </div>
          }
        />
      );

    case 'ActivityDualAllocation_LaborDual':
      return (
        <WizardDescription
          stepSummary={
            // eslint-disable-next-line max-len
            `The employee activity matrix determines the percentage of time spent on activities by each employee on each Business Component during ${year}. ` +
            // eslint-disable-next-line max-len
            'This information helps Amazon Tax determine the total qualified R&D labor expenditures for each Business Component.'
          }
          stepSummaryHeading="Purpose of this step"
          stepDescription={
            <div>
              {// eslint-disable-next-line max-len
              `The tables below show the total percentage of time spent on Activities for each employee that worked on this Business Component in ${year}. The data is derived from the allocations collected in the initial R&D Study surveys. Please review for each employee the total allocated percentages to Activities. If needed, you can make changes to these percentages. All employee percentages must equal 100%.`}
              <br />
              <br />
              {'If you have any questions, please contact '}
              <Link href={`mailto:${process.env.REACT_APP_CONTACT_US_MAIL}`}>
                {process.env.REACT_APP_CONTACT_US_MAIL}
              </Link>
            </div>
          }
        />
      );
    case 'ActivityDualAllocation_Labor':
      return (
        <WizardDescription
          stepDescription={
            <div>
              {
                // eslint-disable-next-line max-len
                'Fill in the employee activity matrix by allocating the percentage of time each employee spent working on the respective activities. All employee percentages must equal 100% for each project.'
              }
              <br />
              <br />
              {'If you have any questions, please '}
              <Link href={SIM_LINK} external>
                submit a SIM ticket
              </Link>
            </div>
          }
          stepSummary={
            // eslint-disable-next-line max-len
            country === 'US'
              ? undefined
              : // eslint-disable-next-line max-len
                `The employee activity matrix determines the percentage of time spent on activities by each team member on each project during ${year}. This information helps Amazon Tax qualify the total qualified R&D labour expenditures of each project.`
          }
          stepSummaryHeading="Purpose of the matrix"
        />
      );

    case 'ProjectDocuments_Labor':
      return country === 'US' ? (
        <WizardDescription
          stepSummary={undefined}
          stepSummaryHeading={undefined}
          stepDescription={
            <div>
              It is recommended that you provide documentation to support employee involvements in
              the projects reported in the survey. Retaining documentation to support the projects
              reported is extremely important. Generally, it is necessary to retain documentation
              for at least seven years. Please keep copies of all the documents included on this
              list in secure storage. Based on your responses, the Tax Department may contact you in
              the future to obtain additional documentation.
              <br />
              <br />
              {'If you have any questions, please '}
              <Link href={SIM_LINK} external>
                submit a SIM ticket
              </Link>
            </div>
          }
        />
      ) : (
        <WizardDescription
          stepDescription={
            <div>
              {
                // eslint-disable-next-line max-len
                'Provide a sample of supporting documentation, including project management reports (such as from Jira or SIMs), engineering reports, six-sigma reports, design documentation, excerpts from Wiki discussions, etc., that would be helpful in supporting the R&D tax credit and the activity allocations of the employees from the previous page. View examples of documentation.'
              }
              <br />
              <br />
              If your project is highly confidential, do not upload documentation. Instead,
              <Link href={SIM_LINK} external>
                submit a SIM ticket
              </Link>
              {' or contact '}
              <Link href="mailto:belldia@amazon.co.uk" external>
                belldia@amazon.co.uk
              </Link>
              <br />
              <br />
              {'If you have any questions, please '}
              <Link href={SIM_LINK} external>
                submit a SIM ticket
              </Link>
            </div>
          }
          stepSummary={
            // eslint-disable-next-line max-len
            country === 'US'
              ? undefined
              : // eslint-disable-next-line max-len
                `This step collects documents to support the R&D tax credit.`
          }
          stepSummaryHeading="Purpose of this step"
        />
      );

    case 'Project_Contract':
      return `Select all projects suppliers worked on in ${year}.
         You can add project(s) if you cannot find in the list.`;

    case 'Activity_Contract':
      return `Select all activities suppliers worked on in ${year}.
         You can add activities if you cannot find in the list.`;

    case 'ProjectAllocation_Contract':
      return (
        `Allocate the percentage of supplier expense to the project(s). 
      You must allocate 100% of each supplier expense for the survey to be considered complete. ` +
        `Alternatively, download this matrix, fill it offline, and upload back. Do not change the 
      format of the downloaded file`
      );

    case 'ActivityAllocation_Contract':
      return (
        `Allocate the percentage of supplier expense to the activity. 
      You must allocate 100% of each supplier expense for the survey to be considered complete. ` +
        `Alternatively, download this matrix, fill it offline, and upload back. Do not change the 
      format of the downloaded file`
      );

    default:
      return null;
  }
};

export const initialState = {
  Employee: {},
  Project: {},
  ProjectDocuments: {
    data: {},
    error: null,
  },
  Activity: {},
  ProjectAllocation: {
    data: {},
    error: null,
    errorMessage: null,
  },
  ActivityAllocation: {
    data: {},
    error: null,
    errorMessage: null,
  },
  ActivityDualAllocation: {
    data: {},
    error: null,
    errorMessage: null,
  },
  Submit: {
    data: [],
    textbox: '',
    error: null,
  },
  activeStep: 0,
  SubmitAPI: {
    response: null,
    error: null,
  },
};

export const validationInitialState = {
  projectAllocationState: {
    modalVisible: false,
    projectName: null,
    projectId: null,
    projectRemoved: false,
  },
  activityAllocationState: {
    modalVisible: false,
    activityName: null,
    activityId: null,
    activityRemoved: false,
  },
  activityDualAllocationState: {
    modalVisible: false,
    activityName: null,
    activityId: null,
    activityRemoved: false,
  },
};

export const apiResponses = {
  Employee: {
    employeeList: [],
  },
  Project: {},
  ProjectDocuments: {},
  Activity: {},
  ProjectAllocation: {},
  ActivityAllocation: {},
  ActivityDualAllocation: {},
  Submit: {},
  SecondaryAssignees: [],
  Vendor: {
    vendorList: [],
  },
};

export const errorMessages = {
  [SURVEY_TYPE.LABOR]: {
    Project: 'You must select at least one project.',
    ActivityAllocation:
      // eslint-disable-next-line max-len
      'You must allocate a total of 100% to each employee in fill employee activity matrix.',
    ActivityDualAllocation:
      // eslint-disable-next-line max-len
      'You must allocate a total of 100% to each employee for each project in fill employee activity matrix.',
    ProjectAllocation:
      'You must allocate a total of 100% to each employee in fill employee project matrix.',
    Submit: 'You must select one checkbox.',
    EntityNames: 'You must enter at least one individual name whom you consulted with.',
  },
  [SURVEY_TYPE.LABOR_DUAL]: {
    Project: 'You must select at least one project.',
    ActivityAllocation:
      // eslint-disable-next-line max-len
      'You must allocate a total of 100% to each employee in fill employee activity matrix.',
    ActivityDualAllocation:
      // eslint-disable-next-line max-len
      'You must allocate a total of 100% to each employee for each project in fill employee activity matrix.',
    ProjectAllocation:
      'You must allocate a total of 100% to each employee in fill employee project matrix.',
    Submit: 'You must select one checkbox.',
    EntityNames: 'You must enter at least one individual name whom you consulted with.',
  },
  [SURVEY_TYPE.CONTRACT]: {
    Project: 'You must select at least one project.',
    ActivityAllocation:
      'You must allocate a total of 100% to each supplier in Activity allocation.',
    ProjectAllocation: 'You must allocate a total of 100% to each supplier in Project allocation.',
    Submit: 'You must select one checkbox.',
    EntityNames: 'You must enter at least one individual name whom you consulted with.',
  },
};

export const INFO_LINK = 'https://w.amazon.com/bin/view/US_RD_Tax_Credit/';

export const AMZN = 'AMZN';
export const COMID = 'COMID';
export const ZOOID = 'ZOOID';
