import Employee from '../components/Employee/components/Employee';
import Vendor from '../components/Vendor/components/Vendor';
import Projects from '../components/Projects/components/Projects';
import Activity from '../components/Activity/component/Activity';
// eslint-disable-next-line import/no-cycle
import ProjectAllocation from '../components/ProjectAllocation/components/ProjectAllocation';
import ActivityDualAllocation from '../components/ActivityAllocation/components/ActivityDualAllocation';
// eslint-disable-next-line import/no-cycle
import Submit from '../components/Submit/components/Submit';
import ProjectDocuments from '../components/ProjectDocuments/components/ProjectDocuments';
import { PROJECT_TEMPLATE_IDS } from '../../../common/constants/templateIds';
import {
  getSurveySteps,
  getSurveyUiTemplate,
  SURVEY_UI_TEMPLATE,
} from '../../../common/constants/surveyType';
import ActivityAllocation from '../components/ActivityAllocation/components/ActivityAllocation';
import AdjustProjectAllocation from '../components/ProjectAllocation/components/AdjustProjectAllocation';

// Order of default Steps in this file and validationSteps in
// survey_page/components/TWizard/TWizard.jsx file should be same.
const defaultSteps = [
  {
    title: 'Review employee list',
    stateKey: 'Employee',
    StepContent: Employee,
    validationOnNextButton: [],
    applicability: [
      SURVEY_UI_TEMPLATE.RadicalLabor,
      SURVEY_UI_TEMPLATE.RadicalLaborDual,
      SURVEY_UI_TEMPLATE.RadicalUKLabor,
      SURVEY_UI_TEMPLATE.RadicalUKADCLLabor,
      SURVEY_UI_TEMPLATE.RadicalUKADCSLabor,
      SURVEY_UI_TEMPLATE.RadicalUKETLabor,
      SURVEY_UI_TEMPLATE.RadicalUKIMDBUKLabor,
      SURVEY_UI_TEMPLATE.RadicalUKVEEQOLabor,
    ],
  },
  {
    title: 'Supplier',
    stateKey: 'Vendor',
    StepContent: Vendor,
    validationOnNextButton: [],
    applicability: [SURVEY_UI_TEMPLATE.RadicalContract],
  },
  {
    title: 'Select activities',
    stateKey: 'Activity',
    StepContent: Activity,
    validationOnNextButton: [],
    applicability: [SURVEY_UI_TEMPLATE.RadicalContract, SURVEY_UI_TEMPLATE.RadicalLabor],
  },
  {
    title: 'Fill employee activity matrix',
    stateKey: 'ActivityAllocation',
    StepContent: ActivityAllocation,
    // use "isActivityHasNoAllocation" function to validate if any activity has zero allocation.
    validationOnNextButton: [],
    applicability: [SURVEY_UI_TEMPLATE.RadicalContract, SURVEY_UI_TEMPLATE.RadicalLabor],
  },
  {
    title: 'Select projects',
    stateKey: 'Project',
    StepContent: Projects,
    validationOnNextButton: [],
    applicability: [
      SURVEY_UI_TEMPLATE.RadicalLabor,
      SURVEY_UI_TEMPLATE.RadicalUKLabor,
      SURVEY_UI_TEMPLATE.RadicalUKADCLLabor,
      SURVEY_UI_TEMPLATE.RadicalUKADCSLabor,
      SURVEY_UI_TEMPLATE.RadicalUKETLabor,
      SURVEY_UI_TEMPLATE.RadicalUKIMDBUKLabor,
      SURVEY_UI_TEMPLATE.RadicalUKVEEQOLabor,
    ],
  },
  {
    title: 'Fill employee project matrix',
    stateKey: 'ProjectAllocation',
    StepContent: ProjectAllocation,
    // use "isProjectHasNoAllocation" function to validate if any project has zero allocation.
    validationOnNextButton: [],
    applicability: [
      SURVEY_UI_TEMPLATE.RadicalLabor,
      SURVEY_UI_TEMPLATE.RadicalUKLabor,
      SURVEY_UI_TEMPLATE.RadicalUKADCLLabor,
      SURVEY_UI_TEMPLATE.RadicalUKADCSLabor,
      SURVEY_UI_TEMPLATE.RadicalUKETLabor,
      SURVEY_UI_TEMPLATE.RadicalUKIMDBUKLabor,
      SURVEY_UI_TEMPLATE.RadicalUKVEEQOLabor,
    ],
  },
  {
    title: 'Review business component time allocation',
    stateKey: 'AdjustProjectAllocation',
    StepContent: AdjustProjectAllocation,
    validationOnNextButton: [],
    applicability: [SURVEY_UI_TEMPLATE.RadicalLaborDual],
  },
  {
    title: 'Select activities',
    stateKey: 'Activity',
    StepContent: Activity,
    validationOnNextButton: [],
    applicability: [
      SURVEY_UI_TEMPLATE.RadicalUKLabor,
      SURVEY_UI_TEMPLATE.RadicalUKADCLLabor,
      SURVEY_UI_TEMPLATE.RadicalUKADCSLabor,
      SURVEY_UI_TEMPLATE.RadicalUKETLabor,
      SURVEY_UI_TEMPLATE.RadicalUKIMDBUKLabor,
      SURVEY_UI_TEMPLATE.RadicalUKVEEQOLabor,
    ],
  },
  {
    title: 'Fill employee activity matrix',
    stateKey: 'ActivityDualAllocation',
    StepContent: ActivityDualAllocation,
    // use "isActivityHasNoAllocation" function to validate if any activity has zero allocation.
    validationOnNextButton: [],
    applicability: [
      SURVEY_UI_TEMPLATE.RadicalLaborDual,
      SURVEY_UI_TEMPLATE.RadicalUKLabor,
      SURVEY_UI_TEMPLATE.RadicalUKADCLLabor,
      SURVEY_UI_TEMPLATE.RadicalUKADCSLabor,
      SURVEY_UI_TEMPLATE.RadicalUKETLabor,
      SURVEY_UI_TEMPLATE.RadicalUKIMDBUKLabor,
      SURVEY_UI_TEMPLATE.RadicalUKVEEQOLabor,
    ],
  },
  {
    title: 'Review and submit',
    stateKey: 'Submit',
    StepContent: Submit,
    validationOnNextButton: [],
    applicability: [],
  },
];

const isProjectDocumentsPresent = () => {
  return defaultSteps.find(step => step.stateKey === 'ProjectDocuments');
};

const steps = (templateId, surveyType, derivedClientId) => {
  const surveySteps = getSurveySteps(
    getSurveyUiTemplate(derivedClientId, surveyType),
    defaultSteps,
  );
  if (
    !PROJECT_TEMPLATE_IDS.includes(templateId) &&
    surveyType !== 'LaborDual' &&
    !isProjectDocumentsPresent()
  ) {
    surveySteps.splice(surveySteps.length - 1, 0, {
      title: 'Upload documentation',
      stateKey: 'ProjectDocuments',
      StepContent: ProjectDocuments,
      isOptional: true,
    });
  }
  return surveySteps;
};

export const getTotalStepCount = (templateId, surveyType, derivedClientId) => {
  return steps(templateId, surveyType, derivedClientId).length;
};

export default steps;
