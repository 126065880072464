import React from 'react';
import { Box, Link, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import '../css/index.css';
import { isEmpty } from 'lodash';

const PhoneToolTiles = ({
  formattedEmployeeName,
  employeeAlias,
  jobTitle,
  badgePhotoURL,
  phoneToolURL,
}) => {
  const phoneToolComponent = !isEmpty(phoneToolURL) ? (
    <Link external href={phoneToolURL}>
      {employeeAlias}
    </Link>
  ) : (
    <TextContent>{employeeAlias}</TextContent>
  );

  return (
    <SpaceBetween direction="horizontal" size="s">
      <img width="70" height="80" src={badgePhotoURL} alt={employeeAlias} />
      <SpaceBetween size="xxxs">
        <Box className="text" variant="h5">
          {formattedEmployeeName}
        </Box>
        {phoneToolComponent}
        <Box className="text" color="text-body-secondary">
          {jobTitle}
        </Box>
      </SpaceBetween>
    </SpaceBetween>
  );
};

PhoneToolTiles.propTypes = {
  formattedEmployeeName: PropTypes.string.isRequired,
  employeeAlias: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  phoneToolURL: PropTypes.string.isRequired,
  badgePhotoURL: PropTypes.string.isRequired,
};

export default PhoneToolTiles;
