import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Box,
  ExpandableSection,
  Icon,
  Input,
  Pagination,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import './ActivityAllocationGrid.css';
import { get } from 'lodash';
import { connect } from 'react-redux';
import AllocationGrid from '../../../AllocationGrid/AllocationGrid';

const ActivityAllocationGrid = ({
  entityData,
  projectList,
  activities,
  surveyType,
  surveyDetails,
  activityDualAllocation,
  setActivityDualAllocation,
  copiedValues,
  copiedColumn,
  setCopiedValues,
}) => {
  const [statusMap, setStatusMap] = useState({});
  const NO_DATA_FOUND_MESSAGE = 'No employee found matching the given criteria.';

  const [employeeQuery, setEmployeeQuery] = React.useState('');
  const [numOfMatches, setNumOfMatches] = React.useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const PAGE_SIZE = 10;

  const entityFilter = useMemo(() => {
    return employeeQuery.trim().toLowerCase();
  }, [employeeQuery]);

  const checkStatus = () => {
    const newStatusMap = {};

    entityData.forEach(entity => {
      const colsEntity = projectList[entity.id]
        ? projectList[entity.id].map(project => ({
            id: project.id,
            name: project.name,
          }))
        : [];

      if (colsEntity.length === 0) {
        newStatusMap[entity.id] = 'PENDING';
        return;
      }

      let allColumnsValid = true;
      let hasData = false;

      colsEntity.forEach(colEntity => {
        const total = activityDualAllocation?.data[entity.id]?.[colEntity.id]?.total
          ? parseFloat(activityDualAllocation?.data[entity.id][colEntity.id].total)
          : null;

        if (total === null || total === 0 || total !== 100) {
          allColumnsValid = false;
        } else {
          hasData = true;
        }
      });

      if (!hasData) {
        allColumnsValid = false;
      }

      newStatusMap[entity.id] = allColumnsValid ? 'SUCCESS' : 'ERROR';
    });

    setStatusMap(newStatusMap);
  };

  useEffect(() => {
    checkStatus();
  }, [activityDualAllocation]);

  useEffect(() => {
    setCurrentPageIndex(1);
  }, [employeeQuery]);

  const dataToBeRendered = useMemo(() => {
    let dataResult = entityData;
    if (entityFilter !== '') {
      dataResult = entityData.filter(entity => {
        return Object.values(entity)
          .join(' ')
          .toLowerCase()
          .includes(entityFilter);
      });
    }

    setNumOfMatches(dataResult.length);
    return dataResult;
  }, [entityData, entityFilter]);

  const dataOnPage = useMemo(() => {
    return dataToBeRendered.slice(
      Math.imul(currentPageIndex - 1, PAGE_SIZE),
      Math.imul(currentPageIndex, PAGE_SIZE),
    );
  }, [dataToBeRendered, currentPageIndex]);

  const TOTAL_PAGE_COUNT = useMemo(() => {
    return Math.ceil(dataToBeRendered.length / PAGE_SIZE);
  }, [dataToBeRendered]);

  const getStatusIcon = status => {
    switch (status) {
      case 'SUCCESS':
        return <Icon name="status-positive" variant="success" size="big" />;
      case 'ERROR':
        return <Icon name="status-warning" variant="error" size="big" />;
      case 'PENDING':
      default:
        return <Icon name="status-positive" variant="disabled" size="big" />;
    }
  };

  return (
    <>
      <SpaceBetween size="l">
        <Box className="table-heading-sectioned-alloc-grid">
          <SpaceBetween size="m" direction="horizontal" className="search-bar-sectioned-alloc-grid">
            <Input
              className="search-input"
              onChange={({ detail }) => setEmployeeQuery(detail.value)}
              value={employeeQuery}
              placeholder="Search"
              type="search"
            />
            {employeeQuery.length > 0 && <span>{numOfMatches} matches</span>}
          </SpaceBetween>
          <Box variant="h3" className="pagination-component-sectioned-alloc-grid">
            <Pagination
              currentPageIndex={currentPageIndex}
              onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex)}
              pagesCount={TOTAL_PAGE_COUNT}
            />
          </Box>
        </Box>
        <div className="activity-allocation-grid">
          {dataOnPage.length === 0 ? (
            <Box className="no-data-found">
              <Alert type="warning">{NO_DATA_FOUND_MESSAGE}</Alert>
            </Box>
          ) : (
            dataOnPage.map(entity => (
              <ExpandableSection
                key={entity.id}
                headerText={
                  <>
                    {entity.name && <span>{entity.name}</span>}
                    {entity.employeeAlias && (
                      <span className="employee-section-header-alias"> {entity.employeeAlias}</span>
                    )}
                  </>
                }
                headerActions={getStatusIcon(statusMap[entity.id])}
                headerDescription={
                  <SpaceBetween size="l" direction="horizontal">
                    {entity.jobTitle && <span>Job title: {entity.jobTitle}</span>}
                    {entity.supervisorName && (
                      <span>
                        {`Reports to: ${entity.supervisorName} ${
                          entity.supervisorAlias ? `(${entity.supervisorAlias})` : ''
                        }`}
                      </span>
                    )}
                    {entity.smeName && (
                      <span>
                        {`SME Name: ${entity.smeName} ${
                          entity.smeAlias ? `(${entity.smeAlias})` : ''
                        }`}
                      </span>
                    )}
                  </SpaceBetween>
                }
                variant="container"
              >
                <AllocationGrid
                  rowsEntity={activities}
                  colsEntity={
                    projectList[entity.id]
                      ? projectList[entity.id].map(project => ({
                          id: project.id,
                          name: project.name,
                        }))
                      : []
                  }
                  gridValues={activityDualAllocation?.data[entity.id] || {}}
                  setGridValues={val => {
                    const newAllocationData = {
                      ...activityDualAllocation,
                      data: {
                        ...activityDualAllocation?.data,
                        [entity.id]: val,
                      },
                    };
                    setActivityDualAllocation(newAllocationData);
                  }}
                  allocationType="Activity"
                  surveyType={surveyType}
                  surveyDetails={surveyDetails}
                  entityForActivityAllocation={entity}
                  copiedValues={copiedValues}
                  copiedColumn={copiedColumn}
                  setCopiedValues={setCopiedValues}
                />
              </ExpandableSection>
            ))
          )}
        </div>
      </SpaceBetween>
    </>
  );
};

ActivityAllocationGrid.propTypes = {
  entityData: PropTypes.array.isRequired,
  projectList: PropTypes.object.isRequired,
  activities: PropTypes.array.isRequired,
  surveyType: PropTypes.string.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  activityDualAllocation: PropTypes.object.isRequired,
  setActivityDualAllocation: PropTypes.func.isRequired,
  copiedValues: PropTypes.object.isRequired,
  copiedColumn: PropTypes.string,
  setCopiedValues: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  activityDualAllocation: get(state, 'activityDualAllocation', {}),
});

export default connect(mapStateToProps)(ActivityAllocationGrid);
