import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
  Textarea,
  Alert,
  Pagination,
  Input,
} from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { get, size } from 'lodash';
import { connect } from 'react-redux';
import ReassignModal from './ReassignModal';
import PhoneToolTiles from './PhoneToolTiles';
import SecondaryAssignee from '../../SecondaryAssignee/SecondaryAssignee';
import DocLink from '../../../../../common/components/doc_link/utils/DocLink';
import getDocById from '../../../../../common/components/doc_link/utils/getDocById';
import { isReadOnly } from '../../../utils/survey_page_utils';
import '../css/Employee.css';

const Employee = ({
  state,
  setState,
  pageContents,
  setPageElements,
  surveyContext,
  surveyId,
  surveyType,
  surveyDetails,
  derivedClientId,
}) => {
  const userResponse = get(surveyContext, [surveyId, 'userResponse'], '{}');
  const [isReassignModalVisible, setIsReassignModalVisible] = useState(false);
  const employeeList = get(pageContents, 'Employee.employeeList', []);
  const NO_DATA_FOUND_MESSAGE = 'No employee found matching the given criteria.';
  const year = get(surveyDetails, 'year', '');

  const [numOfMatches, setNumOfMatches] = useState(0);
  const [employeeFilter, setEmployeeFilter] = useState('');
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const PAGE_SIZE = 10;

  const employeeDataToBeRendered = useMemo(() => {
    setCurrentPageIndex(1);
    let resultData = employeeList;
    if (employeeFilter !== '') {
      resultData = employeeList.filter(employee => {
        return Object.values(employee)
          .join(' ')
          .toLowerCase()
          .includes(employeeFilter);
      });
    }

    setNumOfMatches(resultData.length);
    return resultData;
  }, [employeeList, employeeFilter]);

  const dataOnPage = useMemo(() => {
    return employeeDataToBeRendered.slice(
      Math.imul(currentPageIndex - 1, PAGE_SIZE),
      Math.imul(currentPageIndex, PAGE_SIZE),
    );
  }, [employeeDataToBeRendered, currentPageIndex]);

  const TOTAL_PAGE_COUNT = useMemo(() => {
    return Math.ceil(employeeDataToBeRendered.length / PAGE_SIZE);
  }, [employeeDataToBeRendered.length]);

  const showReassignModal = () => {
    setIsReassignModalVisible(true);
  };
  const hideReassignModal = () => {
    setIsReassignModalVisible(false);
  };

  const primaryHeader = () => {
    return (
      <Header
        counter={`(${size(employeeList)})`}
        variant="h2"
        info={
          <DocLink
            pdf={getDocById(process.env.REACT_APP_FAQ_FILE_ID)}
            text="Why are they assigned to me?"
            variant="info"
          />
        }
      >
        Employees
      </Header>
    );
  };

  const textBoxHandler = ({ detail: { value } }) => {
    const truncatedValue = size(value) > 5000 ? value.substr(0, 5000) : value;
    setState({
      key: 'Employee',
      value: {
        ...state.Employee,
        data: {
          ...state.Employee.data,
          textBoxContent: truncatedValue,
        },
      },
    });
  };

  const PhoneToolTilesList = useCallback(
    ({ data }) => {
      return (
        <>
          {dataOnPage.length === 0 ? (
            <Alert type="warning">{NO_DATA_FOUND_MESSAGE}</Alert>
          ) : (
            <ColumnLayout columns={2}>
              {data.map(profile => {
                return <PhoneToolTiles {...profile} />;
              })}
            </ColumnLayout>
          )}
        </>
      );
    },
    [dataOnPage],
  );

  PhoneToolTilesList.propTypes = {
    data: PropTypes.array.isRequired,
  };

  return (
    <div className="employee-review-page">
      <SpaceBetween size="l">
        <Alert
          statusIconAriaLabel="Info"
          action={
            <Button onClick={showReassignModal} disabled={isReadOnly(surveyDetails)}>
              Reassign the survey
            </Button>
          }
          header="Not the right person to complete the survey?"
        >
          {/* eslint-disable-next-line max-len */}
          {`You can reassign the survey to another person who you think can provide details on the employees’ time spent on ${get(
            surveyDetails,
            'year',
            '',
          )} projects and activities. ` +
            "It is recommended you confirm with the person you're reassigning. " +
            'Once the survey is reassigned, you no longer have access to this survey.'}
        </Alert>
        <Container header={primaryHeader()} className="box-shadow">
          <SpaceBetween size="m">
            <Box color="text-body-secondary">
              {surveyType === 'LaborDual' && derivedClientId === 'Radical'
                ? // eslint-disable-next-line max-len
                  `You will be asked to review the amount of time each employee spent on the Business Component and Activities in ${year}.`
                : // eslint-disable-next-line max-len
                  `You’ll be asked the amount of time each employee spent on various projects and activities.`}
            </Box>

            <Box className="table-heading-sectioned-alloc-grid">
              <SpaceBetween
                size="m"
                direction="horizontal"
                className="search-bar-sectioned-alloc-grid"
              >
                <Input
                  className="search-input"
                  onChange={({ detail }) => {
                    setEmployeeFilter(detail.value.toLowerCase());
                  }}
                  value={employeeFilter}
                  placeholder="Search using any employee attribute"
                  type="search"
                  inputMode="search"
                />
                {employeeFilter.length > 0 && <span>{numOfMatches} matches</span>}
              </SpaceBetween>
              <Box variant="h3" className="pagination-component-sectioned-alloc-grid">
                <Pagination
                  currentPageIndex={currentPageIndex}
                  onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex)}
                  pagesCount={TOTAL_PAGE_COUNT}
                />
              </Box>
            </Box>
            <PhoneToolTilesList data={dataOnPage} />
            <SpaceBetween size="xxxs">
              <Box color="text-body-secondary">
                Need to add or remove an employee from your survey?
              </Box>
              <Box color="text-body-secondary">
                Contact{' '}
                <Link href={`mailto:${process.env.REACT_APP_CONTACT_US_MAIL}`}>
                  {process.env.REACT_APP_CONTACT_US_MAIL}
                </Link>
              </Box>
            </SpaceBetween>
            <SpaceBetween size="xxxs">
              <Box variant="p">
                Describe the overall function of your team in 1-2 sentences (max 5000 characters).
              </Box>
              <Textarea
                onChange={textBoxHandler}
                value={get(state, 'Employee.data.textBoxContent', '')}
                disabled={isReadOnly(surveyDetails)}
              />
            </SpaceBetween>
          </SpaceBetween>
        </Container>
        <SecondaryAssignee
          state={state}
          pageContents={pageContents}
          setPageContents={setPageElements}
          userResponse={userResponse}
        />

        <ReassignModal
          onCancel={hideReassignModal}
          visibility={isReassignModalVisible}
          onConfirm={hideReassignModal}
          surveyType={surveyType}
        />
      </SpaceBetween>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    surveyId: get(state, 'router.location.state.headerDetails.surveyId'),
    surveyContext: get(state, 'entities.surveyDetails.data'),
    derivedClientId: get(state, 'derivedClientId.derivedClientId', undefined),
  };
};

Employee.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  pageContents: PropTypes.object.isRequired,
  setPageElements: PropTypes.func.isRequired,
  surveyId: PropTypes.string.isRequired,
  surveyContext: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  derivedClientId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, null)(Employee);
