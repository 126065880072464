// Will later use this when we need to make this backward compatible for US
/* eslint-disable-next-line no-unused-vars */
import { get, isEmpty, size } from 'lodash';
import { getEntityListPath } from '../../../../common/constants/surveyType';

const validateActivityAllocation = (pageElements, surveyType, activityAllocation) => {
  const entityList = get(pageElements, getEntityListPath(surveyType), []);
  const activity = activityAllocation.data;

  if (isEmpty(activity) || Object.keys(activity).length < entityList.length) return false;
  let validation = true;
  entityList.forEach(employee => {
    if (Number(activity[employee.recordId].total) !== 100) {
      validation = false;
    }
  });

  return validation;
};

export default validateActivityAllocation;
